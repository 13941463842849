import React, { useState, useEffect } from 'react';
import { Layout, theme, Breadcrumb, Row, Col } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import SideBar from '../components/Sidebar';

const { Content, Footer, Sider } = Layout;

const breadcrumbNameMap = {
    '/users': 'Users',
    '/users/new': 'create',
    '/groups': 'Groups',
    '/groups/new': 'create',
    '/applications': 'Applications',
    '/applications/new': 'create',
    //'/apps/1/detail': 'Detail',
    //'/apps/2/detail': 'Detail',
};

const MainLayout = (props) => {

    const {
        token: { colorBgContainer },
    } = theme.useToken();



    useEffect(() => {
        //implement logic here to get userinfo


    }, [])


    return (
        <Layout
            style={{ minHeight: '100vh' }}>
            <Sider collapsible
                // collapsed={collapsed}
                // onCollapse={(value) => setCollapsed(value)}
                style={{ background: colorBgContainer }}

                width={250}>
                <SideBar menu={props.menu} />
            </Sider>
            <Layout className="site-layout">
                <Content style={{ margin: '0 16px' }}>
                    <main style={{ margin: 16 }}>{props.children}</main>
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Copyright © EO4EU {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>

    );
}

export default MainLayout;
