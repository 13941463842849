// src/pages/ToastPage.js
import React from 'react';
import ToastComponent from '../components/ToastComponent';
import MainLayout from '../layouts/MainLayout';
import { Layout, theme, Breadcrumb, Row, Col } from 'antd';

const ToastPage = () => {
    return (

        <MainLayout>

            <ToastComponent />
        </MainLayout>
    );
};

export default ToastPage;
