import React from 'react';
import { UserOutlined, SettingOutlined,HomeOutlined, TeamOutlined, ApartmentOutlined, FileProtectOutlined, LogoutOutlined} from '@ant-design/icons';
import { Menu} from 'antd';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';

import logo from './../logo.svg';

function getItem(label, key, icon, path, children, type) {
  return {
    key,
    icon,
    path,
    children,
    label,
    type
  };
}

const items = [
  {type: 'divider' },
  getItem('Home', '0', <HomeOutlined />, '/home'),

];

function SideBar(props){

    const id = props.menu; //passing menu item key


    const OnMenuItemClick = (e) =>{
    
    }

    return (
        <div>
            <div
            style={{
                height: 160,
                padding: 20,
                borderBottomRightRadius: 10
            }}
            >
              <Row justify="space-around" align="middle">
                <Col span={20}>
                  <img src={logo} alt="logo"/>
                </Col>
              </Row>
            </div>
            <Menu
            defaultSelectedKeys={[id]}
            mode="inline"
            theme='light'
            items={items}
            onClick={(e) =>{OnMenuItemClick(e)}}
            />
        </div>
      );
}

export default SideBar;
