// src/components/auth/RegistrationForm.js
import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import axios from 'axios';
import { Layout, theme, Breadcrumb, Card, Row, Col } from 'antd';

import { v4 as uuid4 } from 'uuid';
import { createNewUser } from '../../services/userService';
import { useNavigate } from 'react-router-dom';
import { showErrorMessage, showSuccessMessage } from '../../utils/utils';
const RegistrationForm = () => {
  const [form] = Form.useForm();
  const [processing, setProcessing] = useState(false);

  const navigate = useNavigate();

  const extractUsername = (str) => {
    let nameMatch = str.match(/^([^@]*)@/);
    let name = nameMatch ? nameMatch[1] : uuid4();

    return name;
  }
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        //form.resetFields();
        //alert(JSON.stringify(values));

        setProcessing(true);

        let bodyContent = {
          email: values.email,
          firstName: (values.firstname == null) ? '' : values.firstname,
          lastName: (values.lastname == null) ? '' : values.lastname,
          username: extractUsername(values.email),
          password: values.password,
        }

        let data = JSON.stringify(bodyContent);

        setTimeout(() => {
          createNewUser(data)
            .then((resp) => {
              console.log(resp);
              //Disable loading state
              setProcessing(false);
              showSuccessMessage("New user created successfully!")
              navigate('/success')
            }).catch((err) => {
              setProcessing(false);
              showErrorMessage("Something went wrong! Error: " + JSON.stringify(err))
              console.log(err)
            })
        }, 3000);

      }
      )
  }

  return (
<Row gutter={[16, 16]} style={{ marginBottom: 16 }} justify="center" align={'bottom'}>
  <Col span={24}>
    <Card bordered={true} hoverable={true} style={{ marginBottom: 16 }} span={24}>
      <Form
        form={form}
        name="registration_form"
        onFinish={onFinish}
        labelAlign="top"  // Add this line to set label alignment to top
        wrapperCol={{ span: 24 }}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: 'Please input your first name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: 'Please input your last name!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Please input your username!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            Register
          </Button>
        </Form.Item>
      </Form>
    </Card>
  </Col>
</Row>);
};

export default RegistrationForm;
