// src/components/auth/ToastComponent.js
import React, { useState } from 'react';

import {  Card, Row, Col,Alert } from 'antd';


const ToastComponent = () => {


  return (
<Row gutter={[16, 16]} style={{ marginBottom: 16 }} justify="center" align={'bottom'}>
  <Col span={24}  style={{ height: 600 }}>
    <Card bordered={true} hoverable={true} style={{ marginBottom: 16 }}  span={24}>
    <Alert message="Thank you for your registration. Currently your account is disable, It will be enable upon review from the Administrator" type="success" />
    </Card>
  </Col>
</Row>);
};

export default ToastComponent;
