// src/App.js
import RegistrationPage from './pages/RegistrationPage';
import React, {useState} from 'react';
import { ConfigProvider, FloatButton, Tooltip, theme} from 'antd';
import {BrowserRouter} from 'react-router-dom';


import { SettingFilled, BgColorsOutlined} from '@ant-design/icons';

import MyRoutes from './routes/MyRoutes';
function App() {
  const { defaultAlgorithm, darkAlgorithm } = theme;
 const [isDarkMode, setIsDarkMode] = useState(false);

 const onChangeThene = () => {
  setIsDarkMode((previousValue) => !previousValue);
 };
  return (
    <ConfigProvider
    theme={{
      token: {
        "colorPrimary": "#"+ window.__RUNTIME_CONFIG__.REACT_APP_PRIMARY_COLOR,
        "fontSize": 15,
        "borderRadius": 14
    }
    }}
>
  <BrowserRouter>
    <MyRoutes/>
  </BrowserRouter>
</ConfigProvider>
  );
}

export default App;
