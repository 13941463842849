import apiRequest from "../api/instance";

export const createNewUser = async( data) =>{
    const config = {
        method: 'POST',
        url: '/Auth/users/create',
        data: data
    };

    return await apiRequest(config);
}