// src/pages/RegistrationPage.js
import React from 'react';
import RegistrationForm from '../components/auth/RegistrationForm';
import MainLayout from './../layouts/MainLayout';
import { Layout, theme, Breadcrumb, Row, Col } from 'antd';

const RegistrationPage = () => {
    return (

        <MainLayout>

            <RegistrationForm />
        </MainLayout>
    );
};

export default RegistrationPage;
