import React from 'react';
import RegistrationPage from '../pages/RegistrationPage';
import ToastPage from '../pages/ToastPage';

import { Route, Routes } from 'react-router-dom';

export default function MyRoutes(){
    return (
        <Routes>
            <Route path="/" 
            element={
                <RegistrationPage />
            } 
            />
            <Route path="/success" 
            element={
                <ToastPage />

            } 
            />
        </Routes>
    )
}